import React from "react";

export interface ImageProcessingData {
  inputImageData: string;
  outputImageData: string;
  success: boolean;
  succeededModules: Record<string, ImageProcessingModuleResults>;
  failedModule: {
    module: ImageProcessingModule;
    results: ImageProcessingModuleResults;
  } | null;
  performance: any;
  score: number;
}

export interface ImageProcess {
  (
    imageProcessingData: ImageProcessingData
  ): Promise<ImageProcessingModuleResults>;
}

export interface ImageProcessingModule {
  name: string;
  init: () => Promise<void>;
  process: ImageProcess;
  getIndicator: (results: ImageProcessingModuleResults) => React.ReactNode;
}

export interface ImageProcessingModuleResults {
  score: number;
  passed: boolean;
  details: any;
}

const calcTotalScore = (imageProcessingData: ImageProcessingData) => {
  const modules = Object.entries(imageProcessingData.succeededModules);
  const sumScore = modules.reduce((acc, [key, module]) => {
    return acc + module.score;
  }, 0);
  return sumScore / modules.length;
};

export class ImageProcessor {
  modules: ImageProcessingModule[];
  constructor(modules: ImageProcessingModule[]) {
    this.modules = modules;
  }

  async init() {
    return Promise.all(this.modules.map((module) => module.init?.()));
  }

  async process(imageData: string): Promise<ImageProcessingData> {
    const imageProcessingData: ImageProcessingData = {
      inputImageData: imageData,
      outputImageData: imageData,
      success: true,
      succeededModules: {},
      failedModule: null,
      performance: {},
      score: 0,
    };
    try {
      for (const module of this.modules) {
        const moduleName = module.name;
        const start = performance.now();
        const moduleResults = await module.process(imageProcessingData);
        if (moduleResults.passed === false) {
          imageProcessingData.success = false;
          imageProcessingData.failedModule = {
            module,
            results: moduleResults,
          };
          imageProcessingData.score = 0;
          break;
        } else {
          imageProcessingData.succeededModules[moduleName] = moduleResults;
          imageProcessingData.score = calcTotalScore(imageProcessingData);
        }
        imageProcessingData.performance[moduleName] = performance.now() - start;
      }
    } catch (e) {
      imageProcessingData.success = false;
      throw e;
    }
    return imageProcessingData;
  }
}
