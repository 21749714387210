import {
  convertImage,
  InputImage,
  OutputImageFormat,
  Size,
} from "./image-transform";

export const getImageSize = async (input: InputImage): Promise<Size> => {
  const imageEl = (await convertImage(
    input,
    OutputImageFormat.ImageElement
  )) as HTMLImageElement;

  const { width, height } = imageEl;

  return {
    width,
    height,
  };
};
