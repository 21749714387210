import React from "react";
import camera from "./camera.svg";
import flipCamera from "./flip-camera.svg";
import "./styles.css";

interface IconProps {
  onClick: () => void;
  buttonText?: string;
}

export const IconShot = ({ onClick }: IconProps) => {
  return (
    <img
      onClick={onClick}
      className="icon shot"
      width="100px"
      src={camera}
      alt="Camera button"
    />
  );
};

export const IconStartRecord = ({ onClick }: IconProps) => {
  return (
    <div onClick={onClick} className="icon record">
      <div className="red-circle" />
    </div>
  );
};

export const IconStopRecord = ({ onClick }: IconProps) => {
  return (
    <div onClick={onClick} className="icon record">
      <div className="stop" />
    </div>
  );
};

export const IconFlipCamera = ({ onClick }: IconProps) => {
  return (
    <img
      onClick={onClick}
      className="flip-camera"
      width="64px"
      src={flipCamera}
      alt="Flip camera"
    />
  );
};

export const IconPlay = ({ onClick , buttonText }: IconProps) => {
  return (
    <div onClick={onClick} className="icon with-text">
      <span className="play-command">{buttonText}</span>
    </div>
  );
};