import React from "react";
import { Size } from "../../modules/image-transform";
import {
  ImageProcessingData,
  ImageProcessingModule,
  ImageProcessingModuleResults,
} from ".";
import { getImageSize } from "../../modules/image-dimensions";

interface ImageMinSizeProps {
  indicator: React.ReactNode;
  minSize?: Size;
}

const DEFAULT_MIN_SIZE = { width: 240, height: 320 };

export class ImageMinSize implements ImageProcessingModule {
  name = "ImageMinSize";
  indicator: React.ReactNode;
  minSize: Size;

  constructor({ minSize, indicator }: ImageMinSizeProps) {
    this.minSize = minSize || DEFAULT_MIN_SIZE;
    this.indicator = indicator;
  }

  async init() {
    return;
  }

  async process(
    imageProcessingData: ImageProcessingData
  ): Promise<ImageProcessingModuleResults> {
    const { width, height } = await getImageSize(
      imageProcessingData.outputImageData
    );

    // Determines if the image is smaller than the minimum accepted size
    // 1 if equal or larger
    // < 1 if smaller
    // e.g. 0.5 means that we capture 120x160 and the minimum accepted size is 240x320
    // this way the image with higher resolution will get a higher score
    const score = Math.min(
      Math.min(width / this.minSize.width, height / this.minSize.height),
      1
    );

    const results: ImageProcessingModuleResults = {
      passed: score === 1,
      score,
      details: {
        originalSize: {
          width,
          height,
        },
        minSize: this.minSize,
      },
    };

    return results;
  }

  getIndicator() {
    return this.indicator;
  }
}
