import { AtoZStatus } from "../api-types/a-to-z-api";
import { Mocker, makeMocker } from "./mock-common";
export const mockAtoZResponse: Mocker<AtoZStatus> = makeMocker<AtoZStatus>({
  aToZLoginUrl: "www.atozLogin.com",
  employeeLogin: "",
  employeeId: "123456",
  personId: "7891011",
  isPasswordSet: false,
  isAccountReady: false,
  errorCode: null,
  errorMessage: "",
  siteName: "DEN-4",
  day1Date: "2023-12-12",
});
