import { StatusEnum } from "./status";

export interface I9Response {
  isRemote?: boolean;
  errorCode?: string;
  secureURL?: string;
  allowRetry?: boolean;
  status?: string;
  isFamilyAndFriends?: boolean;
  PreviousrightToWorkExpired?: boolean;
  rightToWork?: any;
  expiredByDayOne?: boolean;
}

export enum CaaSService {
  ALL_CONTINGENCIES = "AllContingencies",
  I9 = "I9",
}

export type SanitizedContingency = {
  status: string;
  contingencyType: string;
  candidateId?: string;
  expiredByDayOne?: boolean;
  isContingencyOverrideValid?: boolean;
}

export const CAAS_MAINTENANCE_ERROR_MESSAGE =
  "The I9 vendor is currently not available for a scheduled maintenance window. Try Again later.";

export enum CAAS_STATUS {
  Initiated = "Initiated",
  WaitingForASHToCreateOrder = "WaitingForASHToCreateOrder",
  PendingSection1 = "PendingSection1",
  Section1Complete = "Section1Complete",
  Section2Complete = "Section2Complete",
  PendingSection3NoAppointmentScheduled = "PendingSection3NoAppointmentScheduled", // TODO: map to real endpoint from BE
  PendingSection3NoActionRequired = "PendingSection3NoActionRequired", // TODO: map to real endpoint from BE
  PendingSection3 = "PendingSection3",
  Section3Complete = "Section3Complete",
  Archived = "Archived",
  Error = "Error",
  Expired = "Expired",
  Pending = "Pending",
  AwaitingCandidateInfo = "Awaiting Candidate Info",
  Completed = "Completed",
  PendingRejectionReview = "PendingRejectionReview",
  OrderPlacedAtVendor = "OrderPlacedAtVendor",
  PendingManualCheck = "PendingManualCheck",
  PendingF2FCheck = "PendingF2FCheck",
  UkRtwNoCaasIntegration = "UkRtwNoCaasIntegration", // ASH internal use only, default application CAAS status when ASH not integrated with CAAS yet.
  InProgress = "InProgress",
  Rejected = "Rejected",
}

export interface I9CaasProps {
  allowRetry: boolean;
  errorCode: number;
  status: CAAS_STATUS;
  secureURL: string;
  isRemote: boolean;
}

// Ref. https://code.amazon.com/packages/HVHCAASLambda/blobs/development/--/src/domain/api/rtw-contingency-api-response.ts
export interface CaaSRTW {
  status: CAAS_STATUS;
  rtwAuditStatus?: string; // ASH backend checks if this is a property before reading
  PreviousrightToWorkExpired?: boolean; // Not from CaaS, added by ASH
  secureURL?: string;
  expiredByDayOne?: boolean;
}

export interface RTWStatusResponse {
  status: string;
  secureURL?: string;
  rightToWork?: CaaSRTW;
  expiredByDayOne?: boolean;
  errorCode?: string;
}

// All contingency types for CaaS /allContingency response https://code.amazon.com/packages/HVHCAASLambda/blobs/c5b348356dffb33be1ca094942886ebf9af31e6a/--/src/model/enums/contingency-type.ts
export enum ContingencyType {
  AirBGC = "AirBGC",
  BGC = "BGC",
  WorkAuthorization = "WorkAuthorization",
  DrugTest = "DrugTest",
  RightToWork = "RightToWork",
  MedicalCheck = "MedicalCheck",
  AirDrugTest = "AirDrugTest",
}

export type Status = {
  status: StatusEnum;
  expiredByDayOne?: boolean;
};

export type AllContingencies = {
  AirBGC: Status;
  BGC: Status;
  WorkAuthorization: {
    status: CAAS_STATUS | StatusEnum;
    secureURL?: string;
    isRemote?: boolean;
    expiredByDayOne?: boolean;
    allowRetry?: boolean;
    errorCode?: number;
  };
  DrugTest: Status;
  AirDrugTest: Status;
  RightToWork: RTWStatusResponse;
  MedicalCheck: Status;
};
