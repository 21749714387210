import React from "react";
import { getBrightness } from "../../modules/brightness";
import {
  ImageProcessingData,
  ImageProcessingModule,
  ImageProcessingModuleResults,
} from ".";

interface Threshold {
  min: number;
  max: number;
}

interface BrightnessProps {
  threshold?: Threshold;
  ideal?: number;
  indicator: React.ReactNode;
}

interface GetScoreProps {
  value: number;
  ideal?: number;
  threshold: Threshold;
}

export const getScore = ({ value, ideal, threshold }: GetScoreProps) => {
  ideal = ideal || (threshold.max - threshold.min) / 2 + threshold.min;
  if (ideal < threshold.min || ideal > threshold.max) {
    throw new Error(
      "Ideal brightness value should be within the range [min;max]"
    );
  }
  if (value < threshold.min || value > threshold.max) {
    return 0;
  } else if (value === ideal) {
    return 1;
  } else if (value > ideal) {
    return 1 - (value - ideal) / (threshold.max - ideal);
  } else {
    return 1 - (ideal - value) / (ideal - threshold.min);
  }
};

export class Brightness implements ImageProcessingModule {
  name = "Brightness";
  threshold: Threshold;
  ideal?: number;
  indicator: React.ReactNode;

  constructor({
    threshold = { min: 10, max: 30 },
    ideal,
    indicator,
  }: BrightnessProps) {
    this.threshold = threshold;
    this.ideal = ideal;
    this.indicator = indicator;
  }

  async init() {
    return;
  }

  async process(
    imageProcessingData: ImageProcessingData
  ): Promise<ImageProcessingModuleResults> {
    const brightness = await getBrightness(imageProcessingData.outputImageData);

    const passed = true;

    const score = getScore({
      value: brightness,
      ideal: this.ideal,
      threshold: this.threshold,
    });

    return {
      passed,
      score,
      details: {
        brightness,
        threshold: this.threshold,
      },
    };
  }

  getIndicator() {
    return this.indicator;
  }
}
