import React from "react";
import {
  initFaceDetection,
  getFaceDetections,
} from "../../modules/face-detection";
import {
  ImageProcessingData,
  ImageProcessingModule,
  ImageProcessingModuleResults,
} from ".";

enum Errors {
  NoFace = "No face",
  ManyFaces = "Many faces",
}

interface FaceDetectionProps {
  noFaceIndicator: React.ReactNode;
  manyFacesIndicator: React.ReactNode;
  modelsPath?: string;
}

export class FaceDetection implements ImageProcessingModule {
  name = "FaceDetection";
  noFaceIndicator: React.ReactNode;
  manyFacesIndicator: React.ReactNode;
  modelsPath: string;

  constructor({
    noFaceIndicator,
    manyFacesIndicator,
    modelsPath = "/models",
  }: FaceDetectionProps) {
    this.noFaceIndicator = noFaceIndicator;
    this.manyFacesIndicator = manyFacesIndicator;
    this.modelsPath = modelsPath;
  }

  init() {
    return initFaceDetection(this.modelsPath);
  }

  async process(
    imageProcessingData: ImageProcessingData
  ): Promise<ImageProcessingModuleResults> {
    const detections = await getFaceDetections(
      imageProcessingData.outputImageData
    );

    const results: ImageProcessingModuleResults = {
      passed: true,
      score: 0,
      details: {
        detections,
      },
    };

    if (!detections || detections.length === 0) {
      results.passed = false;
      results.details.reason = Errors.NoFace;
    } else if (detections.length > 1) {
      results.passed = false;
      results.details.reason = Errors.ManyFaces;
    } else {
      results.score = detections[0].score;
    }

    return results;
  }

  getIndicator(results: ImageProcessingModuleResults) {
    if (results.details.reason === Errors.NoFace) {
      return this.noFaceIndicator;
    } else if (results.details.reason === Errors.ManyFaces) {
      return this.manyFacesIndicator;
    }
  }
}
