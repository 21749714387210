import React from "react";
import {
  convertImage,
  resize,
  OutputImageFormat,
  Size,
} from "../../modules/image-transform";
import {
  ImageProcessingData,
  ImageProcessingModule,
  ImageProcessingModuleResults,
} from ".";

interface ImageResizerProps {
  indicator: React.ReactNode;
  targetSize: Size;
}

export class ImageResizer implements ImageProcessingModule {
  name = "ImageResizer";
  indicator: React.ReactNode;
  targetSize: Size;

  constructor({ targetSize, indicator }: ImageResizerProps) {
    this.targetSize = targetSize;
    this.indicator = indicator;
  }

  async init() {
    return;
  }

  async process(
    imageProcessingData: ImageProcessingData
  ): Promise<ImageProcessingModuleResults> {
    const imageEl = (await convertImage(
      imageProcessingData.outputImageData,
      OutputImageFormat.ImageElement
    )) as HTMLImageElement;

    // Determines how small is the actual image size to the desired size
    // 1 if greater and can be resized
    // < 1 if smaller and can not be resized
    // e.g. 0.5 means that we capture 300:150 and want to resize it to 600:300
    // e.g. 1 means that we capture 600:300 or greater and want to resize it to 600:300
    // this way the image with higher resolution will get a higher score
    const score = Math.min(
      Math.min(
        imageEl.width / this.targetSize.width,
        imageEl.height / this.targetSize.height
      ),
      1
    );

    const results: ImageProcessingModuleResults = {
      passed: true,
      score,
      details: {
        originalSize: {
          width: imageEl.width,
          height: imageEl.height,
        },
        targetSize: this.targetSize,
      },
    };

    if (
      this.targetSize.width < imageEl.width ||
      this.targetSize.height < imageEl.height
    ) {
      const resizedImageBase64 = (await resize(
        imageProcessingData.outputImageData,
        this.targetSize,
        OutputImageFormat.Base64
      )) as string;

      imageProcessingData.outputImageData = resizedImageBase64;
    }

    return results;
  }

  getIndicator() {
    return this.indicator;
  }
}
