import { Mocker, makeMocker } from "./mock-common";
import { TaskStatusModel, TaskType, ZapposStatusEnum, ZapposStatusModel } from "../api-types/task-status-api";
import { StatusEnum } from "../api-types";

export const mockNewHireVideoResponse: Mocker<TaskStatusModel> = makeMocker<TaskStatusModel>({
  taskType: "NEW_HIRE_VIDEO",
  taskStatus: StatusEnum.NOT_STARTED,
  candidateId: "253c57c0-152c-11ee-b39d-e9573de9bcbd",
  taskId: "66b7b090-152d-11ee-9981-3711cb2eb8c1",
});

export const mockPicResponse: Mocker<TaskStatusModel> = makeMocker<TaskStatusModel>({
  taskType: "PIC",
  taskStatus: StatusEnum.NOT_STARTED,
  candidateId: "253c57c0-152c-11ee-b39d-e9573de9bcbd",
  taskId: "66b7b090-152d-11ee-9981-3711cb2eb8c1",
});

export const mockZapposResponse: Mocker<ZapposStatusModel> = makeMocker<ZapposStatusModel>({
  taskType: "ZAPPOS_SHOE_PROMOTION",
  taskStatus: ZapposStatusEnum.INELIGIBLE,
  candidateId: "253c57c0-152c-11ee-b39d-e9573de9bcbd",
  taskId: "fIJGKX4Wppoi",
});

export const mockTaskStatusResponse = (params?: {
  newHireVideoResponse?: Partial<TaskStatusModel>;
  picResponse?: Partial<TaskStatusModel>;
  zapposResponse?: Partial<ZapposStatusModel>;
}): Array<TaskStatusModel | ZapposStatusModel> => [
  mockNewHireVideoResponse(params?.newHireVideoResponse),
  mockPicResponse(params?.picResponse),
  mockZapposResponse(params?.zapposResponse),
];
