import React, { useMemo } from "react";
import { CameraMode, VideoState } from ".";
import { IconShot, IconStartRecord, IconStopRecord } from "../icons/camera";

interface UseCameraIconProps {
  mode: CameraMode;
  videoState?: VideoState;
  onShotCallback?: () => void;
  onVideoStartCallback?: () => void;
  onVideoStopCallback?: () => void;
}

export const useCameraIcon = ({
  mode,
  videoState,
  onShotCallback,
  onVideoStartCallback,
  onVideoStopCallback,
}: UseCameraIconProps) => {
  return useMemo(() => {
    if (mode === CameraMode.Selfie || mode === CameraMode.Document) {
      return <IconShot onClick={onShotCallback!} />;
    } else if (mode === CameraMode.Video) {
      if (videoState === VideoState.Stopped) {
        return <IconStartRecord onClick={onVideoStartCallback!} />;
      } else if (videoState === VideoState.Recording) {
        return <IconStopRecord onClick={onVideoStopCallback!} />;
      }
    }
    return null;
  }, [
    mode,
    videoState,
    onShotCallback,
    onVideoStartCallback,
    onVideoStopCallback,
  ]);
};
