const IDEAL_WIDTH = 1280;
const IDEAL_HEIGHT = 868;

interface AttachStreamToProps {
  videoEl: HTMLVideoElement | null;
  autoPlay?: boolean;
  facingMode?: string;
}

export const attachStreamTo = async ({
  videoEl,
  autoPlay,
  facingMode = "user",
}: AttachStreamToProps) => {
  if (!videoEl) {
    return;
  }
  videoEl.pause();
  videoEl.srcObject = null;

  const stream = await window.navigator.mediaDevices.getUserMedia({
    video: {
      width: { ideal: IDEAL_WIDTH },
      height: { ideal: IDEAL_HEIGHT },
      facingMode,
    },
  });
  videoEl.srcObject = stream;
  autoPlay && videoEl.play();
};

export const stopMediaStream = (videoEl: HTMLVideoElement | null) => {
  if (!videoEl) {
    return;
  }
  (videoEl.srcObject as any)?.getTracks()[0]?.stop();
};
