import { ApplicationManageDataResponse, ApplicationState } from "../api-types/application-manage-api";
import { Mocker, makeMocker } from "./mock-common";

export const mockApplicationManageDataResponse: Mocker<ApplicationManageDataResponse> =
  makeMocker<ApplicationManageDataResponse>({
    enableCSS: false,
    hideWithdrawApplication: false,
    applicationState: ApplicationState.EVALUATION_PENDING,
    scheduleId: 'SCH-US-0000005013'
  });
