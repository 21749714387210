import { getImageSize } from "./image-dimensions";

export interface BoundingBox {
  x: number;
  y: number;
  width: number;
  height: number;
}

export enum OutputImageFormat {
  Base64 = "base64",
  ImageElement = "imageElement",
  ImageData = "imageData",
}

export interface Size {
  width: number;
  height: number;
}

export type InputImage = HTMLImageElement | string | HTMLVideoElement;

const base64toImageElement = (input: string): Promise<HTMLImageElement> => {
  return new Promise((resolve) => {
    const imageEl = document.createElement("img");
    imageEl.src = input;

    imageEl.addEventListener("load", () => {
      resolve(imageEl);
    });
  });
};

const toBase64 = async (
  input: HTMLImageElement | string | HTMLVideoElement,
  boundingBox?: BoundingBox
) => {
  if (typeof input === "string") {
    return input;
  }

  const width =
    input instanceof HTMLVideoElement ? input.videoWidth : input.width;
  const height =
    input instanceof HTMLVideoElement ? input.videoHeight : input.height;

  boundingBox = boundingBox || {
    x: 0,
    y: 0,
    width,
    height,
  };

  const canvas = document.createElement("canvas");
  canvas.width = boundingBox.width;
  canvas.height = boundingBox.height;
  const ctx = canvas.getContext("2d");

  ctx!.drawImage(
    input,
    boundingBox.x,
    boundingBox.y,
    boundingBox.width,
    boundingBox.height,
    0,
    0,
    boundingBox.width,
    boundingBox.height
  );
  return canvas.toDataURL("image/jpeg");
};

const toImageData = (input: HTMLImageElement | HTMLVideoElement): ImageData => {
  const canvas = document.createElement("canvas");
  const ctx = canvas.getContext("2d");
  const width =
    input instanceof HTMLVideoElement ? input.videoWidth : input.width;
  const height =
    input instanceof HTMLVideoElement ? input.videoHeight : input.height;
  canvas.width = width;
  canvas.height = height;

  ctx!.drawImage(input, 0, 0, width, height);
  const imageData = ctx!.getImageData(0, 0, width, height);
  return imageData;
};

export const convertImage = async (
  input: InputImage,
  outputFormat: OutputImageFormat
): Promise<string | HTMLImageElement | ImageData> => {
  if (typeof input === "string" && outputFormat === OutputImageFormat.Base64) {
    return input;
  } else if (
    input instanceof HTMLImageElement &&
    outputFormat === OutputImageFormat.ImageElement
  ) {
    return input;
  }

  if (outputFormat === OutputImageFormat.ImageData) {
    let imageEl = input;
    if (typeof input === "string") {
      imageEl = await base64toImageElement(input);
    }
    return toImageData(imageEl as HTMLImageElement | HTMLVideoElement);
  }

  // ImageElement, VideoElement => Base64
  if (outputFormat === OutputImageFormat.Base64) {
    return toBase64(input);
  }

  // Base64 => ImageElement
  if (typeof input === "string") {
    return base64toImageElement(input);
  }

  // VideoElement => Base64 => ImageElement
  const base64 = await toBase64(input);
  return base64toImageElement(base64);
};

export const cropImage = async (
  input: InputImage,
  boundingBox: BoundingBox,
  outputFormat: OutputImageFormat
) => {
  const imageEl = (await convertImage(
    input,
    OutputImageFormat.ImageElement
  )) as HTMLImageElement;

  const croppedBase64 = await toBase64(imageEl, boundingBox);
  return convertImage(croppedBase64, outputFormat);
};

export const resize = async (
  input: InputImage,
  size: Size,
  outputFormat: OutputImageFormat
) => {
  const canvas = document.createElement("canvas");
  const ctx = canvas.getContext("2d");
  const imageEl = (await convertImage(
    input,
    OutputImageFormat.ImageElement
  )) as HTMLImageElement;
  canvas.width = size.width;
  canvas.height = size.height;
  ctx?.drawImage(
    imageEl,
    0,
    0,
    imageEl.width,
    imageEl.height,
    0,
    0,
    size.width,
    size.height
  );
  const imageBase64 = canvas.toDataURL("image/jpeg");
  return convertImage(imageBase64, outputFormat);
};

/**
 * Resizes image preserving aspect ratio, such that it fits within maximum dimensions.
 * @param input
 * @param maximumDimensions
 * @param outputFormat
 */
export const resizePreserveAspectRatio = async (
  input: InputImage,
  maximumDimensions: Size,
  outputFormat: OutputImageFormat
) => {
  const { width, height } = await getImageSize(input);

  const widthRatio = maximumDimensions.width / width;
  const heightRatio = maximumDimensions.height / height;

  const resizeRatio = Math.min(widthRatio, heightRatio);

  const newWidth = width * resizeRatio;
  const newHeight = height * resizeRatio;

  return resize(input, { width: newWidth, height: newHeight }, outputFormat);
};
