import { convertImage, OutputImageFormat } from "../image-transform";

const average = (average: number, index: number, a: number) => {
  if (index === 1) {
    return a;
  }
  return (average * (index - 1) + a) / index;
};

export const calcBrightness = (data: Uint8ClampedArray) => {
  let r,
    g,
    b,
    avg,
    totalAvg = 0;

  for (var x = 0, i = 1, len = data.length; x < len; x += 4, i++) {
    r = data[x];
    g = data[x + 1];
    b = data[x + 2];

    avg = Math.floor((r + g + b) / 3);
    totalAvg = average(totalAvg, i, avg);
  }

  return ~~totalAvg;
};

/**
 * Checks if the image has enough brightness
 * @param input string | HTMLImageElement | HTMLVideoElement | HTMLCanvasElement Takes either image data or imageEl canvasEl or videoEl
 * @returns Promise<number> Brightness
 */
export const getBrightness = async (
  input: HTMLImageElement | string
): Promise<number> => {
  const imageData = (await convertImage(
    input,
    OutputImageFormat.ImageData
  )) as ImageData;
  return calcBrightness(imageData.data);
};
