import { DocumentStatus } from "../api-types/onbase-api";
import { Mocker, makeMocker } from "./mock-common";

// checklist/api/document-status
export const mockOnbaseResponse: Mocker<DocumentStatus> = makeMocker<DocumentStatus>({
  results: [
    {
      error: "candidateNotFound",
      candidateId: "CIDNA6300164035",
      documentsAvailable: false,
      documentCompletionStatus: "notApplicable",
      onbaseLoginUrl: null,
    },
  ],
});
