import { Mocker, makeMocker } from "./mock-common";
import { AppointmentInfo, NheDetails, VirtualAppointmentInfo } from "../api-types/nhe-api";

export const mockNheDetailsResponse: Mocker<NheDetails> = makeMocker<NheDetails>({
  nheDate: "Oct 1, 2023",
  nheTimeRange: "10:00AM - 10:15AM",
  address: "2265  Oak Street, Old Forge test, Old Forge, ID 13420",
  timezone: "UTC",
  inPast: false,
  isStartTimeInPast: false,
  status: "RESERVED",
  supportedLanguage: ["en-US", "es-US"],
  displayReadyTimeSlot: "10:00AM - 10:15AM UTC Oct 1, 2023",
  displayTimeZone: 'Hawaii-Aleutian Time',
});

export const mockAppointmentInfo: Mocker<AppointmentInfo> = makeMocker<AppointmentInfo>({
  timezone: "UTC",
  startTimestamp: "2022-02-12T14:34:00.000Z",
  endTimestamp: "2022-02-12T15:14:00.000Z",
  locationType: "PHYSICAL",
  status: "RESERVED",
  physicalAddress: {
    addressLine1: "2265 Oak Street",
    addressLine2: "",
    city: "Old Forge",
    state: "ID",
    country: "US",
    zipCode: "13420",
  },
  displayReadyLocation: "2265 Oak Street, Old Forge, ID 13420",
  supportedLanguage: ["en-US", "es-US"],
  appointmentPublicText: "Appointment type details",
  locationPublicText: "The door is on the southwest corner of the building",
  displayReadyTimeSlot: "2:34PM - 3:14PM",
  displayReadyStartDate: "February 12, 2022",
  displayTimeZone:'Hawaii-Aleutian Time',
});

export const mockVirtualAppointmentInfo: Mocker<VirtualAppointmentInfo> = makeMocker<VirtualAppointmentInfo>({
  timezone: "UTC",
  startTimestamp: "2022-02-12T14:34:00.000Z",
  endTimestamp: "2022-02-12T15:14:00.000Z",
  status: "RESERVED",
  supportedLanguage: ["en-US", "es-US"],
  locationType: "VIRTUAL_LINK",
  displayReadyLocation: "https://example.com/virtual-appt/123456",
  virtualMeetingLink: "https://example.com/virtual-appt/123456",
  appointmentPublicText: "Appointment type details",
  locationPublicText: "Make sure your webcam is working before the appointment",
  displayReadyTimeSlot: "2:34PM - 3:14PM",
  displayReadyStartDate: "February 12, 2022",
  displayTimeZone:'Hawaii-Aleutian Time',
});
