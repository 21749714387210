import * as faceapi from "face-api.js";
import { convertImage, OutputImageFormat } from "../image-transform";

let isInit = false;

export const initFaceDetection = async (modelsPath: string = '/models') => {
  if (isInit) {
    return;
  }
  return Promise.all([
    faceapi.nets.tinyFaceDetector.loadFromUri(modelsPath),
  ]).then(() => {
    isInit = true;
  });
};

/**
 * Detects faces on a photo
 * @param photo string | HTMLImageElement | HTMLVideoElement | HTMLCanvasElement Takes either image data or imageEl canvasEl or videoEl
 * @returns Promise<faceapi.FaceDetection[]>
 */
export const getFaceDetections = async (
  input: HTMLImageElement | string
): Promise<faceapi.FaceDetection[]> => {
  if (!isInit) {
    throw new Error("The ML models were not loaded");
  }

  const imageEl = (await convertImage(
    input,
    OutputImageFormat.ImageElement
  )) as HTMLImageElement;

  const detections = await faceapi.detectAllFaces(
    imageEl,
    new faceapi.TinyFaceDetectorOptions()
  );

  return detections as any;
};
