import { BoundingBox } from "../image-transform";
import { createRange } from "../../helpers/range";

// Average face boundaries, calculated by analyzing 10 typical badge photos in phonetool
//
// const AVG_BOX = {
//   x: 24.28281205161442,
//   y: 56.02541886494585,
//   width: 69.41268923484225,
//   height: 64.38996329984388,
// };

// Percentage of the height that face is taking on the badge photo, e.g. 45% by default
// This coefficient determines how big a face will be on the badge photo. Higher the value - bigger the face.
// We start with 45% and then slowly zoom in if the face is too close to the border until the image fits the badge boundaries
// so we can crop the image.
export const ZoomLevels = createRange({ min: 0.45, max: 0.575, step: 0.0125 });

const getMagicNumberHeight = (zoomLevel: number = 0): number =>
  1 / ZoomLevels[zoomLevel];
const magicNumberRatioLR = 0.4; // ratio for which the margin is added to left vs right
const magicNumberRatioTB = 0.6; // ratio for which the margin is added to top vs bottom
const aspectRatio = 3 / 4; // 3:4 aspect ratio width:height;

/**
 * Crops the image based on face position
 * @param faceBoundingBox Rect contains face boundaries
 * @returns BoundingBox badge bounding box
 */
export const getBadgeBoundingBox = (
  faceBoundingBox: BoundingBox,
  zoomLevel?: number
): BoundingBox => {
  let calculatedHeight = ~~(
    faceBoundingBox.height * getMagicNumberHeight(zoomLevel)
  );
  let calculatedWidth = ~~(calculatedHeight * aspectRatio);

  const badgeBoundingBox: BoundingBox = {
    x: ~~(
      faceBoundingBox.x -
      (calculatedWidth - faceBoundingBox.width) * magicNumberRatioLR
    ),
    y: ~~(
      faceBoundingBox.y -
      (calculatedHeight - faceBoundingBox.height) * magicNumberRatioTB
    ),
    width: calculatedWidth,
    height: calculatedHeight,
  };

  return badgeBoundingBox;
};
