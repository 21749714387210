// This folder will contain and re-export all API types for the ASH UI <-> Backend interface

export {
  ApplicationManageDataResponse,
  ApplicationState,
  SkipAppointmentOneEligibility,
} from "./application-manage-api";
export { CandidateInfo, AdditionalNationalIdsInfo, IdInfo } from "./cds-api";
export {
  I9CaasProps,
  CAAS_STATUS,
  CaaSRTW,
  RTWStatusResponse,
  AllContingencies,
  ContingencyType,
  CAAS_MAINTENANCE_ERROR_MESSAGE,
  CaaSService,
  I9Response,
  SanitizedContingency
} from "./caas-api";
export { JobDetailsModel } from "./job-container-schedule-api";
export { NheDetails, AppointmentInfo, PhysicalAppointmentInfo, VirtualAppointmentInfo } from "./nhe-api";
export {
  StatusEnum,
  NHEAppointmentStatusEnum,
  NHEAppointmentApplicationTypeEnum,
  BACKGROUND_CHECK_STATUSES,
  BackgroundCheckStatus,
  CAAS_DRUG_TEST_STATUSES,
  CaasDrugTestEnum,
  MEDICAL_CHECK_STATUSES,
  MedicalCheckStatus,
  RightToWorkStatus,
} from "./status";
export { TaskStatusModel, TaskType, ZapposStatusModel, ZapposStatusEnum } from "./task-status-api";
export { ZapposMagicLinkRequest, ZapposMagicLinkResponse } from "./zappos-api";
