import React from "react";
import { getBadgeBoundingBox, ZoomLevels } from "../../modules/badge-cropper";
import {
  ImageProcessingData,
  ImageProcessingModule,
  ImageProcessingModuleResults,
} from ".";
import {
  BoundingBox,
  convertImage,
  cropImage,
  OutputImageFormat,
} from "../../modules/image-transform";

interface ImageCropperProps {
  indicator: React.ReactNode;
  allowZoomIn?: boolean;
}

export class ImageCropper implements ImageProcessingModule {
  name = "ImageCropper";
  indicator: any;
  allowZoomIn: boolean;

  constructor({ indicator, allowZoomIn = false }: ImageCropperProps) {
    this.indicator = indicator;
    this.allowZoomIn = allowZoomIn;
  }

  async init() {
    return;
  }

  async process(
    imageProcessingData: ImageProcessingData
  ): Promise<ImageProcessingModuleResults> {
    const { FaceDetection } = imageProcessingData.succeededModules;
    const faceBoundingBox = FaceDetection.details.detections[0].box;

    const imageEl = (await convertImage(
      imageProcessingData.outputImageData,
      OutputImageFormat.ImageElement
    )) as HTMLImageElement;

    let zoomLevel = 0;
    let badgeBoundingBox: BoundingBox;
    let isBadgeWithinImageBoundaries: boolean;

    do {
      badgeBoundingBox = getBadgeBoundingBox(faceBoundingBox, zoomLevel++);
      isBadgeWithinImageBoundaries = !(
        badgeBoundingBox.x + badgeBoundingBox.width > imageEl.width ||
        badgeBoundingBox.y + badgeBoundingBox.height > imageEl.height ||
        badgeBoundingBox.x < 0 ||
        badgeBoundingBox.y < 0
      );
      if (isBadgeWithinImageBoundaries) {
        break;
      }
    } while (zoomLevel < ZoomLevels.length && this.allowZoomIn);

    const croppedImageBase64 = (await cropImage(
      imageProcessingData.outputImageData,
      badgeBoundingBox,
      OutputImageFormat.Base64
    )) as string;

    imageProcessingData.outputImageData = croppedImageBase64;

    const results: ImageProcessingModuleResults = {
      passed: isBadgeWithinImageBoundaries,
      score: 1,
      details: {
        faceBoundingBox,
        badgeBoundingBox,
      },
    };

    return results;
  }

  getIndicator() {
    return this.indicator;
  }
}
