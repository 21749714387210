import { Mocker, makeMocker } from "./mock-common";
import { JobDetailsModel } from "../api-types/job-container-schedule-api";

export const mockJobDetailsResponse: Mocker<JobDetailsModel> = makeMocker<JobDetailsModel>({
  scheduleId: "SCH-US-0000095689",
  businessLine: "FC",
  geoClusterId: "GL-US-0042",
  externalJobTitle: "Amazon Grocery Shopper",
  scheduleText: "Sat, Sun, Mon, Tue 9:00 PM - 6:30 AM",
  firstDayOnSite: "2023-10-01",
  startTime: "15:00",
  briefJobDescription: "You'll work inside a Whole Foods Market, preparing customer grocery orders for delivery.",
  basePay: 15,
  address: "1555 N. Chrisman Rd",
  city: "Tracy",
  state: "CA",
  postalCode: "95304",
  image: "https://m.media-amazon.com/images/G/01/HVHJobDetails/I_Grocery_Shopper._CB1198675309_.svg",
  shiftDifferential: 2.9,
  signOnBonus: 5,
  surgePay: 9,
  hoursPerWeek: 38,
  totalPayRate: 17.9,
  hoursPerWeekL10N: "38",
  firstDayOnSiteL10N: "2023-10-01",
  startTimeL10N: "3:00:00 PM",
  basePayL10N: "$15.00",
  signOnBonusL10N: "$5",
  surgePayL10N: "$9.00",
  totalPayRateL10N: "$17.90",
  requiredLanguage: ["es-US"],
  employmentType: "Regular",
  employmentTypeL10N: "Regular",
  currencyCode: "USD",
  scheduleBannerText: "Additional 5$ bonus schedule and 9$ Surge pay",
  siteId: "SITE-OAK4",
  countryGroup: "US",
  timezone: "America/Los_Angeles",
  jobId: "JOB-US-0000068341",
  isScheduleReleased: false,
  startDateStatus: "TENTATIVE",
  publicText:
    "<p>If your name does not match the name on your ID, you must bring original document(s) supporting the change. This could include marriage certificate, court order, etc. Please note: photocopies will not be accepted</p>",
  siteJobPreviewVideoLink: "https://example.com/some-video-file.mp4",
});
