import { Mocker, makeMocker } from "./mock-common";

import { NheDetails, NheErrorDetails } from "../api-types/nhe-api";

export const mockRTWSuccessResponse: Mocker<NheDetails> = makeMocker<NheDetails>({
  nheDate: "Oct 1, 2023",
  nheTimeRange: "10:00AM - 10:15AM",
  address: "2265  Oak Street, Old Forge test, Old Forge, ID 13420",
  timezone: "UTC",
  inPast: false,
  isStartTimeInPast: false,
  status: "RESERVED",
  supportedLanguage: ["en-US", "es-US"],
  displayReadyTimeSlot: "10:00AM - 10:15AM UTC Oct 1, 2023",
  displayTimeZone:'Hawaii-Aleutian Time',
});

export const mockRTWFailResponse: Mocker<NheErrorDetails> = makeMocker<NheErrorDetails>({
  errorStatusCode: 404,
  errorCode: "207",
  errorMessage: "RTW_NO_VALID_APPOINTMENT",
});
