// prettier-ignore
export type MockPartial<T extends object> = {
  [P in keyof T]?:
  T[P] extends (infer V)[] ? V[] :
  T[P] extends object | undefined ? MockPartial<T[P]> :
  T[P];
}

export type Mocker<T extends object> = (overrides?: MockPartial<T>) => T;

/**
 * Make a copy of a template object with some properties replaced recursively. The overrides can explicitly set values to "null" but "undefined" will be ignored
 *
 * @param template The object to copy
 * @param overrides An object containing fields to replace recursively in the copy
 *
 * @returns A copy of template with fields replaced by values in overrides
 */
export function mockValueWithOverrides<T extends object>(template: T, overrides?: MockPartial<T>): T {
  const copy: T = JSON.parse(JSON.stringify(template));

  function mergeRecursive<U extends object>(destination: U, replacements?: MockPartial<U>) {
    if (replacements !== null) {
      for (const key in replacements) {
        const replacement = replacements[key];
        if (replacement !== undefined) {
          const destinationValue = destination[key];
          if (
            destinationValue !== null &&
            typeof destinationValue === "object" &&
            !Array.isArray(destinationValue) &&
            replacement !== null &&
            typeof replacement === "object"
          ) {
            mergeRecursive(destinationValue, replacement);
          } else {
            Object.assign(destination, { [key]: replacement });
          }
        }
      }
    }
  }

  mergeRecursive(copy, overrides);

  return copy;
}

/**
 * Make a function that returns mock values and supports adding recursive overrides
 *
 * @see {@link mockValueWithOverrides}
 *
 * @param template default value to return
 * @returns A function which provides mock values
 */
export function makeMocker<T extends object>(template: T): Mocker<T> {
  return (overrides?: MockPartial<T>) => mockValueWithOverrides(template, overrides);
}
