import { Mocker, makeMocker } from "./mock-common";

export const mockAppConfigResponse: Mocker<Object> = makeMocker<Object>({
  configurations: {
    configurationsArray: [
      {
        configurationName: "cssEnabledStates",
        configurationType: "application_states_config",
        countries: {
          US: ["SCHEDULE_SELECTION_PENDING", "READY_TO_HIRE", "CONTINGENCIES_PENDING"],
        },
      },
      {
        configurationName: "isAdsEnabled",
        defaultValue: true,
      },
      {
        configurationName: "isAlumniEnabled",
        defaultValue: false,
      },
      {
        configurationName: "isJobContainerEnabled",
        defaultValue: true,
      },
      {
        configurationName: "isAtoZEnabled",
        defaultValue: true,
        siteFilters: [
          "SITE-DEN4",
          "SITE-AKC1",
          "SITE-BHM1",
          "SITE-CLE2",
          "SITE-DCA6",
          "SITE-HOU8",
          "SITE-HSV1",
          "SITE-JAX7",
          "SITE-LFT1",
          "SITE-MCO2",
          "SITE-MEM3",
          "SITE-MEM4",
          "SITE-MQY1",
          "SITE-OKC1",
          "SITE-RDU1",
          "SITE-SMF6",
          "SITE-ABQ1",
          "SITE-BDL3",
          "SITE-BFL1",
          "SITE-BTR1",
          "SITE-CHO1",
          "SITE-DEN2",
          "SITE-DEN3",
          "SITE-DET1",
          "SITE-DFW7",
          "SITE-DFW8",
          "SITE-DSW5",
          "SITE-HDT2",
          "SITE-HDT3",
          "SITE-HDT9",
          "SITE-HOU2",
          "SITE-HOU6",
          "SITE-JFK8",
          "SITE-LAS7",
          "SITE-LAS8",
          "SITE-MDT4",
          "SITE-OAK3",
          "SITE-OKC2",
          "SITE-SAT1",
          "SITE-FWA4",
          "SITE-PCW1",
          "SITE-LIT1",
          "SITE-MGE9",
          "SITE-SYR1",
          "SITE-BDL4",
          "SITE-DML3",
          "SITE-OMA2",
          "SITE-ILG1",
          "SITE-RIC2",
          "SITE-STL8",
        ],
      },
      {
        configurationName: "enableAtoZTask",
        configurationType: "task_feature_flag_config",
        taskId: "ATOZ_TASK",
        defaultValue: true,
        overrideConditions: [
          // Never enable for customer service, first match wins
          {
            overrideValue: false,
            countryList: [],
            siteList: [],
            businessLineList: [],
            jobTypeList: [],
            jobIdList: [],
            requisitionCategoryList: ["Customer Service"],
          },
        ],
      },
      {
        configurationName: "workAuthorizationDocumentConfigs",
        countries: {
          "United States": {
            multiDropdown: false,
            commonDocuments: [],
            documentRecord: {
              DRIVERS_LICENSE: {
                documentName: "Driver’s License",
                documentDescription: "Driver’s License to be uploaded as work authorization documents",
                documentType: "DRIVERS_LICENSE",
                requiredPages: ["Front", "Back"],
                requiredIdInput: "DriverLicenseId",
              },
              PASSPORT: {
                documentName: "Passport",
                documentDescription: "Passport to be uploaded as work authorization documents",
                documentType: "PASSPORT",
                requiredPages: ["Front"],
                requiredIdInput: "PassportId",
              },
            },
            applicants: [
              {
                applicantId: "FOREIGNER",
                documents: ["DRIVERS_LICENSE"],
                enableConditions: {
                  siteIds: ["SITE-OAK4"],
                  businessLines: [],
                  jobIds: [],
                },
              },
              {
                applicantId: "MEXICO_CITIZEN",
                documents: ["PASSPORT"],
                enableConditions: {
                  siteIds: ["SITE-OAK4", "SITE-BUF5"],
                  businessLines: [],
                  jobIds: [],
                },
              },
            ],
          },
        },
      },
      {
        configurationName: "enableBadgePhotoTask",
        configurationType: "task_feature_flag_config",
        taskId: "BADGE_PHOTO_TASK",
        defaultValue: true,
        overrideConditions: [
          {
            overrideValue: false,
            countryList: [],
            siteList: [],
            businessLineList: [],
            jobTypeList: [],
            jobIdList: [],
            requisitionCategoryList: ["Customer Service"],
          },
        ],
      },
      {
        configurationName: "enableI9Task",
        configurationType: "task_feature_flag_config",
        taskId: "I9_TASK",
        defaultValue: true,
        overrideConditions: [
          {
            overrideValue: true,
            countryList: [],
            siteList: [],
            businessLineList: [],
            jobTypeList: [],
            jobIdList: [],
            requisitionCategoryList: ["Customer Service"],
          },
        ],
      },
      {
        configurationName: "enableDrugTestTask",
        configurationType: "task_feature_flag_config",
        taskId: "DRUG_TEST_TASK",
        defaultValue: true,
        overrideConditions: [
          {
            overrideValue: false,
            countryList: [],
            siteList: [],
            businessLineList: [],
            jobTypeList: [],
            jobIdList: [],
            requisitionCategoryList: ["Customer Service"],
          },
        ],
      },
      {
        configurationName: "enableBackgroundCheckTask",
        configurationType: "task_feature_flag_config",
        taskId: "BACKGROUND_CHECK_TASK",
        defaultValue: true,
      },
      {
        configurationName: "enableNHEVideoCheckTask",
        configurationType: "task_feature_flag_config",
        taskId: "NHE_VIDEO_TASK",
        defaultValue: true,
      },
      {
        configurationName: "enablePackageI9Task",
        configurationType: "task_feature_flag_config",
        taskId: "PACKAGE_I9_TASK",
        defaultValue: true,
        overrideConditions: [
          {
            overrideValue: true,
            countryList: [],
            siteList: [],
            businessLineList: [],
            jobTypeList: [],
            jobIdList: [],
            requisitionCategoryList: [],
          },
        ],
      },
      {
        configurationName: "enableBadgePhotoDuringAppointmentTask",
        configurationType: "task_feature_flag_config",
        taskId: "BP_DURING_APPOINTMENT_TASK",
        defaultValue: true,
        overrideConditions: [
          {
            overrideValue: false,
            countryList: [],
            siteList: [],
            businessLineList: [],
            jobTypeList: [],
            jobIdList: [],
            requisitionCategoryList: ["Customer Service"],
          },
        ],
      },
      {
        configurationName: "enableMedicalCheckInScreeningTask",
        configurationType: "task_feature_flag_config",
        taskId: "Medical_CHECK_IN_SCREENING_TASK",
        defaultValue: true,
        overrideConditions: [
          {
            overrideValue: false,
            countryList: [],
            siteList: [],
            businessLineList: [],
            jobTypeList: [],
            jobIdList: [],
            requisitionCategoryList: ["Customer Service"],
          },
        ],
      },
      {
        configurationName: "enableBackgroundCheckInScreeningTask",
        configurationType: "task_feature_flag_config",
        taskId: "BGC_CHECK_IN_SCREENING_TASK",
        defaultValue: true,
      },
      {
        configurationName: "enableAirBackgroundCheckInScreeningTask",
        configurationType: "task_feature_flag_config",
        taskId: "AIR_BGC_IN_SCREENING_CHECK_TASK",
        defaultValue: true,
        overrideConditions: [
          {
            overrideValue: false,
            countryList: [],
            siteList: [],
            businessLineList: [],
            jobTypeList: [],
            jobIdList: [],
            requisitionCategoryList: ["Customer Service"],
          },
        ],
      },
      {
        configurationName: "enableDTInScreeningTask",
        configurationType: "task_feature_flag_config",
        taskId: "DT_IN_SCREENING_CHECK_TASK",
        defaultValue: true,
        overrideConditions: [
          {
            overrideValue: false,
            countryList: [],
            siteList: [],
            businessLineList: [],
            jobTypeList: [],
            jobIdList: [],
            requisitionCategoryList: ["Customer Service"],
          },
        ],
      },
      {
        configurationName: "enablePICInScreeningTask",
        configurationType: "task_feature_flag_config",
        taskId: "PIC_IN_SCREENING_CHECK_TASK",
        defaultValue: true,
        overrideConditions: [
          {
            overrideValue: false,
            countryList: [],
            siteList: [],
            businessLineList: [],
            jobTypeList: [],
            jobIdList: [],
            requisitionCategoryList: ["Customer Service"],
          },
        ],
      },
      {
        configurationName: "enableMYSPTask",
        configurationType: "task_feature_flag_config",
        taskId: "MYSP_TASK",
        defaultValue: true,
        overrideConditions: [
          {
            overrideValue: false,
            countryList: [],
            siteList: [],
            businessLineList: [],
            jobTypeList: [],
            jobIdList: [],
            requisitionCategoryList: ["Customer Service"],
          },
        ],
      },
      {
        configurationName: "enableEmplpoymentPaperworkTask",
        configurationType: "task_feature_flag_config",
        taskId: "EMPLOYMENT_PAPERWORK_TASK",
        defaultValue: true,
        overrideConditions: [
          {
            overrideValue: true,
            countryList: [],
            siteList: [],
            businessLineList: [],
            jobTypeList: [],
            jobIdList: [],
            requisitionCategoryList: ["Customer Service"],
          },
        ],
      },
      {
        configurationName: "enableDocumentUploadTask",
        configurationType: "task_feature_flag_config",
        taskId: "DOCUMENT_TASK",
        defaultValue: false,
        overrideConditions: [
          {
            overrideValue: false,
            countryList: [],
            siteList: [],
            businessLineList: [],
            jobTypeList: [],
            jobIdList: [],
            requisitionCategoryList: ["Customer Service"],
          },
        ],
      },
      {
        configurationName: "enableAppointmentDetailsTask",
        configurationType: "task_feature_flag_config",
        taskId: "APPOINTMENT_DETAILS_TASK",
        defaultValue: true,
        overrideConditions: [
          {
            overrideValue: true,
            countryList: [],
            siteList: [],
            businessLineList: [],
            jobTypeList: [],
            jobIdList: [],
            requisitionCategoryList: [],
          },
        ],
      },
      {
        configurationName: "EnableUpdateURLForEDM",
        configurationType: "task_feature_flag_config",
        taskId: "EDM_URL_UPDATE",
        defaultValue: false,
        overrideConditions: [
          {
            overrideValue: false,
            countryList: [],
            siteList: [],
            businessLineList: [],
            jobTypeList: [],
            jobIdList: [],
            requisitionCategoryList: ["Customer Service"],
          },
          {
            overrideValue: false,
            countryList: ["BR", "CO", "CR"],
            siteList: [],
            businessLineList: [],
            jobTypeList: [],
            jobIdList: [],
            requisitionCategoryList: [],
          },
          {
            overrideValue: true,
            countryList: ["US"],
            siteList: [],
            businessLineList: [],
            jobTypeList: [],
            jobIdList: [],
            requisitionCategoryList: [],
          },
        ],
      },
      {
        configurationName: "RightToWorkTaskConfigs",
        configurationType: "task_definition_config",
        taskId: "RIGHT_TO_WORK_TASK_DEFINITION",
        definitions: {
          taskTitle: "I9",
          isIntegratedWithCaaS: true,
          requiredDocumentURL: "",
        },
      },
      {
        configurationName: "enableZapposMagicLink",
        configurationType: "task_feature_flag_config",
        taskId: "ZAPPOS_MAGIC_LINK_TASK",
        defaultValue: false,
      },
      {
        configurationName: "isGlobalIdEnabled",
        defaultValue: true,
      },
      {
        configurationName: "EnableUpdateURLForSSO",
        defaultValue: true,
      },
      {
        configurationName: "EnableJwtAuthForSf",
        defaultValue: false,
      },
      {
        configurationName: "useVirtualNHE",
        defaultValue: false,
      },
      {
        configurationName: "disableCaasI9GETCall",
        defaultValue: false,
      },
      {
        configurationName: "enableVirtualAppointmentUpdate",
        defaultValue: true,
      },
      {
        configurationName: "enableCaasAllContingenciesForBGC",
        defaultValue: false,
      },
      {
        configurationName: "enableCaasAllContingenciesForAirBGC",
        defaultValue: false,
      },
      {
        configurationName: "enableCaasAllContingenciesForMedicalCheck",
        defaultValue: false,
      },
      {
        configurationName: "enableCaasAllContingenciesForDT",
        defaultValue: false,
      },
      {
        configurationName: "enableCaasAllContingenciesForAirDT",
        defaultValue: false,
      },
      {
        configurationName: "enableCaasAllContingenciesForRTW",
        defaultValue: false,
      },
      {
        configurationName: "enableCaasAllContingenciesForWorkAuth",
        defaultValue: false,
      },
      {
        configurationName: "SFAxiosQuery",
        defaultValue: true,
      },
    ],
  },
  isCached: true,
});
