export enum ApplicationState {
  EVALUATION_PENDING = "EVALUATION_PENDING",
  APPT_1_PENDING = "APPT_1_PENDING",
  HIRE_COMPLETE = "HIRE_COMPLETE",
  START_DATE_CONFIRMATION_PENDING = "START_DATE_CONFIRMATION_PENDING",
  SCHEDULE_SELECTION_PENDING = "SCHEDULE_SELECTION_PENDING",
  CONTINGENCIES_PENDING = "CONTINGENCIES_PENDING",
  READY_TO_HIRE = "READY_TO_HIRE",
  POOLING = "POOLING",
  PENDING_APPT_1_SCHEDULING = "PENDING_APPT_1_SCHEDULING",
}

export const SkipAppointmentOneEligibilityList = [
  "ATTEND_BY_ELIGIBILITY_CHECK",
  "ATTEND_BY_USER_SELECTION",
  "SKIP_BY_ELIGIBILITY_CHECK",
  "SKIP_BY_USER_SELECTION",
] as const;

export type SkipAppointmentOneEligibility = (typeof SkipAppointmentOneEligibilityList)[number];

export type ApplicationManageDataResponse = {
  enableCSS: boolean;
  hideWithdrawApplication: boolean;
  applicationState: ApplicationState | null;
  scheduleId?: string;
  applicationStepSubStep?: string;
  isAlumniApplication?: boolean;
  skipAppointmentOne?: SkipAppointmentOneEligibility;
};
