import React, { useCallback, useEffect, useRef, useState } from "react";
import { attachStreamTo, stopMediaStream } from "./video-stream";
import "./styles.css";
import { convertImage, OutputImageFormat } from "../../modules/image-transform";
import { useCameraIcon } from "./use-camera-icon";
import { IconPlay } from "../icons/camera";

export enum CameraMode {
  Document = "document",
  Selfie = "selfie",
  Video = "video",
  Auto = "auto",
}

export enum VideoState {
  Stopped,
  Recording,
}

interface CameraProps {
  mode: CameraMode;
  onShot?: (imageData: any, width: number, height: number) => void;
  onVideo?: (videoData: string) => void;
  readonly?: boolean;
  autoFramesInterval?: number;
  autoPlay?: boolean;
  onReady?: () => void;
  facingMode?: string;
  outputFormat?: string;
  startButtonText?: string;
}

const DEFAULT_AUTO_FRAMES_INTERVAL = 500;

export const Camera = ({
  mode,
  onShot,
  onVideo,
  readonly = false,
  autoFramesInterval = DEFAULT_AUTO_FRAMES_INTERVAL,
  autoPlay,
  onReady,
  facingMode = "user",
  outputFormat = OutputImageFormat.Base64,
  startButtonText
}: CameraProps) => {
  const [loadedData, setLoadedData] = useState(false);
  const [cameraReady, setCameraReady] = useState<boolean>(false);
  const [videoState, setVideoState] = useState<VideoState>(VideoState.Stopped);
  const videoRef = useRef<HTMLVideoElement | null>(null);

  const onPlayCallback = useCallback(() => {
    setCameraReady(true);
    onReady?.();
  }, [onReady]);

  const onLoadedDataCallback = useCallback(() => {
    setLoadedData(true);
  }, []);

  const onShotCallback = useCallback(async () => {
    const videoEl = videoRef.current;
    if (readonly || !onShot || !videoEl || !cameraReady || !loadedData) {
      return;
    }
    const imageData = await convertImage(
      videoEl,
      outputFormat as OutputImageFormat
    );

    onShot(imageData, videoEl.videoWidth, videoEl.videoHeight);
  }, [onShot, readonly, cameraReady, outputFormat, loadedData]);

  const autoShotCallback = useCallback(() => {
    if (mode !== CameraMode.Auto) {
      return;
    }
    onShotCallback();
  }, [mode, onShotCallback]);

  useEffect(() => {
    const autoCatchInterval = setInterval(() => {
      autoShotCallback();
    }, autoFramesInterval);

    return () => {
      clearInterval(autoCatchInterval);
    };
  }, [autoShotCallback, autoFramesInterval]);

  useEffect(() => {
    const videoEl = videoRef.current;
    attachStreamTo({ videoEl, autoPlay, facingMode });

    return () => {
      stopMediaStream(videoEl);
    };
  }, [autoPlay, facingMode]);

  const onVideoStopCallback = useCallback(async () => {
    if (readonly || !onVideo || !videoRef.current) {
      return;
    }
    setVideoState(VideoState.Stopped);
    const imageData = (await convertImage(
      videoRef.current!,
      OutputImageFormat.Base64
    )) as string;
    onVideo(imageData);
  }, [onVideo, readonly]);

  const onVideoStartCallback = useCallback(() => {
    if (readonly) {
      return;
    }
    setVideoState(VideoState.Recording);
  }, [readonly]);

  const cameraIcon = useCameraIcon({
    mode,
    videoState,
    onShotCallback,
    onVideoStartCallback,
    onVideoStopCallback,
  });

  const wrapperClassName = `video-wrapper mode-${mode?.toLowerCase()}`;
  const videoClassName = `${
    cameraReady ? "visible" : ""
  } facing-mode-${facingMode}`;
  const overlayClassName = `video-overlay ${readonly ? "readonly" : ""}`;


  if (!startButtonText) {
    startButtonText = "Start"; //default value for button
  }

  return (
    <div className={wrapperClassName}>
      <video
        ref={videoRef}
        muted
        className={videoClassName}
        onPlay={onPlayCallback}
        onLoadedData={onLoadedDataCallback}
        playsInline
      ></video>
      {cameraReady && loadedData && (
        <div className={overlayClassName}>{cameraIcon}</div>
      )}
      {!cameraReady && !autoPlay && (
        <IconPlay
          onClick={() => {
            videoRef.current?.play();
          }}
          buttonText = {startButtonText}
        ></IconPlay>
      )}
    </div>
  );
};
