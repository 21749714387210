import { Size } from "../modules/image-transform";

export const createRange = ({
  min,
  max,
  step,
}: {
  min: number;
  max: number;
  step: number;
}): number[] => {
  const range = [];
  for (let i = min; i <= max; i += step) {
    range.push(i);
  }
  return range;
};

const DEFAULT_NUM_OF_STEPS = 3;
export const MIN_SIZE: Size = { width: 240, height: 320 };

export const createResizeRange = (
  { width, height }: Size,
  numOfSteps: number = DEFAULT_NUM_OF_STEPS
): Size[] => {
  if (width <= MIN_SIZE.width || height <= MIN_SIZE.height) {
    return [{ width, height }];
  }

  const widthRange = createRange({
    min: MIN_SIZE.width,
    max: width,
    step: (width - MIN_SIZE.width) / numOfSteps,
  });
  const heightRange = createRange({
    min: MIN_SIZE.height,
    max: height,
    step: (height - MIN_SIZE.height) / numOfSteps,
  });

  const resizeRange: Map<string, Size> = widthRange
    .map((width, index) => {
      const height = heightRange[index];
      return {
        width: ~~width,
        height: ~~height,
      };
    })
    .reverse()
    .reduce((acc, { width, height }) => {
      const key = `${width}x${height}`;
      if (!acc.has(key)) {
        acc.set(key, { width, height });
      }
      return acc;
    }, new Map<string, Size>())
  
  return Array.from(resizeRange.values());
};
