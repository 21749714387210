import { StatusEnum } from "./status";

export type TaskType =
  | "PIC"
  | "ZAPPOS_SHOE_PROMOTION"
  | "NEW_HIRE_VIDEO" // Legacy task ID for backward compatibility
  | `NEW_HIRE_VIDEO_${string}`
  | `JOB_PREVIEW_VIDEO#${string}`;

export interface TaskStatusModel {
  taskType: TaskType;
  taskStatus: StatusEnum;
  candidateId: string;
  taskId: string;
  expirationDate?: string;
  applicationId?: string;
  completedDate?: string;
  timeToLive?: number;
}

export interface ZapposStatusModel extends Omit<TaskStatusModel, "taskStatus"> {
  taskStatus: ZapposStatusEnum | StatusEnum;
}

export enum ZapposStatusEnum {
  ELIGIBLE = "ELIGIBLE",
  INELIGIBLE = "INELIGIBLE",
}
